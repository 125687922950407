import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import WinterContainer from "../../components/Winter/WinterContainer"
import SEO from "../../components/seo"

import "../../styles/Global.css"
import "../../styles/layout.module.css"

const DescubriSignUpPage = props => {
  return (
    <div>
      <SEO title="Winter Sun is Calling" />
      <WinterContainer page={"Participar"} />
    </div>
  )
}

export default DescubriSignUpPage
